import { useMemo } from 'react';
import FavoriteButtonWithCount, { FavoriteButtonWithCountProps } from 'src/components/FavoriteButtonWithCount/FavoriteButtonWithCount';
import { CURRENCY } from 'src/data/constants';
import { ImagesGallery } from 'src/modules/gallery';
import Button from 'src/ui-kit/Button';
import Stack from 'src/ui-kit/Stack/Stack';
import StylishBox from 'src/ui-kit/StylishBox/StylishBox';
import { Typography } from 'src/ui-kit/Typography';
import getBundleInfo from 'src/utils/products/getBundleInfo';
import { getGalleryItemsFromProductImages } from 'src/utils/products/getGalleryItemsFromProductImages';
import { getImgAlt } from 'src/utils/products/getImgAlt';
import { Bundle, ImageUrls, Unit } from '../../../../api/types';
import PriceRange from '../../../../components/PricesRange/PricesRange';
import { useLocalize } from '../../../../services/localize/useLocalize';
import { useProductPageExperiment } from '../../../../utils/catalog/useProductPageExperiment';
import formatWeight from '../../../../utils/products/formatWeight';
import { BadgesLocalizationsType } from '../../helpers/getBadgesLocalizations';
import Badges from '../Badges/Badges';
import { useFixedButtonsAreVisible } from './useFixedButtonsAreVisible';
import { ButtonProps } from 'src/ui-kit/Button/Button';
import { useTheme } from 'src/ui-kit/theme';

export interface ProductMainInfoProps {
  images: ImageUrls[];
  title: string;
  minPrice: number;
  maxPrice: number;
  unit: Unit;
  weight: number | null;
  volume: number | null;
  bundle: Bundle;
  inStock: boolean;
  badges: BadgesLocalizationsType;
  favoriteButtonProps: FavoriteButtonWithCountProps;
  onButtonClick(): void;
}

const ProductMainInfo = (props: ProductMainInfoProps) => {
  const {
    title,
    minPrice,
    maxPrice,
    unit,
    weight,
    volume,
    images,
    inStock,
    bundle,
    badges,
    favoriteButtonProps,
    onButtonClick,
  } = props;

  const localize = useLocalize();
  const theme = useTheme();

  const {
    buttonsRef,
    fixedButtonsAreVisible,
  } = useFixedButtonsAreVisible();
  const viewWeight = formatWeight(unit, weight, volume, localize);
  const viewBundle = getBundleInfo(bundle, localize);
  const {
    experimentIsActive,
  } = useProductPageExperiment();
  const pricesAreEqual = minPrice === maxPrice;

  const buttonInStockKey = experimentIsActive ? 'view_prices' : 'products.add.to-cart';
  const buttonText = localize(inStock ? buttonInStockKey : 'out_of_stock');

  const imgAlt = getImgAlt(title, localize);
  const galleryItems = useMemo(
    () => getGalleryItemsFromProductImages(images, imgAlt, title),
    [images, imgAlt, title],
  );

  const mainButtonProps: ButtonProps = {
    onClick: onButtonClick,
    variant: 'primary',
    disabled: !inStock,
    children: buttonText,
  };

  return (
    <Stack
      flexDirection={{
        general: 'row',
        phone: 'column',
      }}
      sbs={{
        maxWidth: '936px',
      }}
    >
      <StylishBox
        sbs={{
          m: {
            general: '0 40px 0 0',
            tablet: '0 16px 0 0',
            phone: '0 auto 40px auto',
          },
          position: 'relative',
          width: {
            general: '272px',
            tablet: '344px',
            phone: '328px',
          },
          maxWidth: {
            phone: '100%',
          },
        }}
      >
        <ImagesGallery
          items={galleryItems}
        />
        <Badges {...badges} withOpacity={!inStock} />
      </StylishBox>

      <StylishBox sbs={{ flex: '1' }}>
        <Typography
          element='h1'
          variant={{
            general: 'h4',
            phone: 'text1',
          }}

          sbs={{
            mb: {
              general: '16px',
              phone: '8px',
            },
          }}
        >
          {title}
        </Typography>

        <Typography
          variant='text6'
          color='gray500'
          sbs={{
            mb: {
              general: '20px',
              phone: '12px',
            },
          }}
        >
          {viewWeight}
          {viewBundle}
        </Typography>

        <div>
          <Typography
            variant='text6'
            color='gray500'
            element='span'
            sbs={{
              mr: '8px',
            }}
          >
            {localize(pricesAreEqual ? 'products.price' : 'products.prices')}
          </Typography>
          <PriceRange startPrice={minPrice} endPrice={maxPrice} currency={CURRENCY} />
        </div>

        <StylishBox
          elementProps={{
            ref: buttonsRef,
          }}
          sbs={{
            display: 'flex',
            alignItems: 'center',
            maxWidth: {
              general: '328px',
              tablet: '280px',
              phone: '100%',
            },
            mt: '16px',
          }}
        >
          <Button
            dataMarker='to cart'
            sbs={{
              order: { tabletPhone: '1' },
              mr: {
                desktop: '8px',
                general: '0',
              },
              ml: {
                tabletPhone: '12px',
              },
            }}
            {...mainButtonProps}
          />
          <FavoriteButtonWithCount {...favoriteButtonProps} />
        </StylishBox>

        {
          fixedButtonsAreVisible && <StylishBox
            sbs={{
              position: 'fixed',
              bottom: '0',
              left: '0',
              zIndex: '2',
              display: 'flex',
              alignItems: 'center',
              p: '16px',
              bgColor: theme.base.color.gray100,
              width: '100%',
            }}
          >
            <FavoriteButtonWithCount {...favoriteButtonProps} />
            <Button sbs={{ ml: '12px' }} {...mainButtonProps} />
          </StylishBox>
        }
      </StylishBox>
    </Stack >
  );
};

export default ProductMainInfo;
