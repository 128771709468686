import { useState } from "react";
import Stack from "src/ui-kit/Stack/Stack";
import { Typography } from "src/ui-kit/Typography";
import FavoriteButton, { FavoriteButtonProps } from "../FavoriteButton/FavoriteButton";

export interface FavoriteButtonWithCountProps extends FavoriteButtonProps {
  count?: number;
  isLoggedIn: boolean;
}

const FavoriteButtonWithCount = (props: FavoriteButtonWithCountProps) => {
  const {
    count: initCount,
    isActive,
    onClick,
    isLoggedIn,
  } = props;
  const [count, setCount] = useState(Number(initCount));

  const handleFavoriteClick = () => {
    if (isLoggedIn) {
      setCount(prevCount => prevCount + (isActive ? -1 : 1));
    }

    onClick();
  };

  return (
    <Stack
      dataMarker="Favorite button wrapper"
      className="FavoriteButtonWithCount"
      flexDirection='column'
      alignItems='center'
      sbs={{
        minWidth: '40px',
      }}
    >
      <FavoriteButton isActive={isActive} onClick={handleFavoriteClick} />
      {count > 0 && (
        <Typography
          dataMarker='Favorite button count'
          variant='caption1'
          color='gray500'
          align='center'
        >
          {count > 999 ? '999+' : count}
        </Typography>
      )}
    </Stack>
  );
};

export default FavoriteButtonWithCount;
