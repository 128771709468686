import throttle from "lodash/throttle";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { phone } from "src/styles/media";

export const useFixedButtonsAreVisible = () => {
  const buttonsRef = useRef<HTMLDivElement>(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const isPhone = useMediaQuery({ query: phone });
  const fixedButtonsAreVisible = isScrolled && isPhone;

  useEffect(() => {
    const handleScroll = throttle(() => {
      if(buttonsRef.current) {
        const headerHeight = document.getElementById('header')?.offsetHeight || 0;
        const { bottom } = buttonsRef.current.getBoundingClientRect();
        const elementIsScrolled = bottom <= headerHeight;
        setIsScrolled(elementIsScrolled);
      }
    }, 500);

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isPhone]);

  return {
    fixedButtonsAreVisible,
    buttonsRef,
  };
};
