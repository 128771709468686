import css from 'styled-jsx/css';
import { desktop, phone_tablet } from '../../styles/media';


//@todo: use emotion or StylishBox for this component to access color vars
const styles = css`
  .ProductsSlider__item {
    flex-shrink: 0;
    border: 1px solid #efefef;
  }
  .ProductsSlider__item {
    width: 20%;
  }

  .ProductsSlider__info {
    height: 40px;
    position: relative;
    margin-bottom: 20px;
  }

  .ProductsSlider__titleShape {
    width: 124px;
    height: 40px;
    margin: 0 auto;
  }

  .ProductsSlider__actions {
    display: none;
  }

  @media ${desktop} {
    .ProductsSlider__actions {
      display: flex;
      align-self: center;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media ${phone_tablet} {
    .ProductsSlider__item {
      width: 228px;
    }
  }
`;

export default styles;
