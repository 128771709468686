import Cookies from "js-cookie";
import { useSearchParams } from "next/navigation";

export const useSavedSortQuery = () => {
  const searchParams = useSearchParams();
  const sortFromCookie = Cookies.get('sort');
  const sortFromQuery = searchParams.get('sort');
  const savedSort = sortFromQuery || sortFromCookie;

  return savedSort ? `?sort=${savedSort}` : '';
};
